<template lang="pug">
.canvas-watermark(
  v-if="getWatermark.isEnabled"
  :style="watermarkContainerStyle"
)
  img.canvas-watermark__image(
    :src="watermarkUrl"
  )
  span.canvas-watermark__background(
    :style="watermarkBackgroundStyle"
  )
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'CanvasWatermark',
  computed: {
    ...mapState([
      'isDownloadPreviewOpened',
      'showGenieModal',
      'showStoryboardOverlay',
    ]),
    ...mapGetters([
      'getCanvasZoom',
    ]),
    ...mapGetters('canvasElements', ['getWatermark', 'getCanvasSize']),
    zoom() {
      // note - for modals, should set the canvas zoom to default: 1 as the container was rescaled.
      // note source: can search "elementWipeStyle" in CanvasElement.vue
      const sceneCanvasZoom = (this.isDownloadPreviewOpened || this.showGenieModal || this.showStoryboardOverlay) ? 1 : this.getCanvasZoom;
      return sceneCanvasZoom || 1;
    },
    watermarkUrl() {
      return this.getWatermark.url;
    },
    isBackgroundEnabled() {
      return this.getWatermark.background.isEnabled;
    },
    watermarkContainerStyle() {
      const styleObject = {};

      const { size, direction, opacity, position } = this.getWatermark;

      let left = null;
      let top = null;
      let right = null;
      let bottom = null;
      let transform = '';

      if (direction === 'center') {
        top = '50%';
        left = '50%';
        transform = 'translate(-50%, -50%)';
      } else {
        if (direction.includes('left')) {
          left = `${position.x * this.zoom}px`;
        }
        if (direction.includes('right')) {
          right = `${position.x * this.zoom}px`;
        }
        if (direction === 'left' || direction === 'right') {
          top = '50%';
          transform = 'translateY(-50%)';
        }

        if (direction.includes('top')) {
          top = `${position.y * this.zoom}px`;
        }
        if (direction.includes('bottom')) {
          bottom = `${position.y * this.zoom}px`;
        }
        if (direction === 'top' || direction === 'bottom') {
          left = '50%';
          transform = 'translateX(-50%)';
        }
      }

      styleObject.width = `${size.width * this.zoom}px`;
      styleObject.height = `${size.height * this.zoom}px`;

      if (top !== null) styleObject.top = top;
      if (bottom !== null) styleObject.bottom = bottom;
      if (left !== null) styleObject.left = left;
      if (right !== null) styleObject.right = right;

      if (transform) styleObject.transform = transform;

      const getOpacity = opacity / 100;
      styleObject.opacity = getOpacity;

      return styleObject;
    },
    watermarkBackgroundStyle() {
      const styleObject = {};

      const { color, scale, borderRadius, opacity } = this.getWatermark.background;

      if (this.isBackgroundEnabled) {
        if (color) {
          let backgroundColor = '#fff';

          if (typeof color === 'string') {
            backgroundColor = color;
          } else if (color.points) {
            // Gradient
            backgroundColor = `linear-gradient(${color.degree}deg`;
            const { points } = color;
            for (let i = 0; i < points.length; i += 1) {
              const point = points[i];
              backgroundColor += `, ${point.color} ${point.percentage}%`;
            }
            backgroundColor += ')';
          }

          styleObject.background = backgroundColor;
        }

        if (borderRadius) {
          styleObject.borderRadius = `${borderRadius * this.zoom}px`;
        }

        if (typeof opacity !== 'undefined') {
          const getOpacity = opacity / 100;
          styleObject.opacity = getOpacity;
        }

        const x = scale && scale.x ? scale.x : 1;
        const y = scale && scale.y ? scale.y : 1;

        styleObject.transform = `translate(-50%, -50%) scale(${x}, ${y})`;
      }

      return styleObject;
    },
  },
};
</script>

<style lang="scss">
.canvas-watermark {
  position: absolute;
  pointer-events: none;
  z-index: 4;
}

.canvas-watermark__image {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;
}

.canvas-watermark__background {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
