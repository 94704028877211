<template lang="pug">
.scene(
  :style="styleObject"
  :class="[sceneClass, { 'is-playing': checkIsPlaying }, `scene--${id}`, 'notranslate']"
  ref="canvasScene"
)
  template(v-if="hasWatermark")
    .watermark-top
    .watermark-bot
  //- scene element has to be drr so that animation direction can work properly
  //- the draggable, rotatable, resizable and selectable part is in CanvasElement.vue
  //- for testing, can change
  //-  :class="setBlendClass(item)"
  //-  :class="[setBlendClass(item), `scene__element--${item.data.id}`]"
  drr.scene__element(
    v-if="populateElements"
    :class="[setBlendClass(item), `scene__element--${item.data.id}`, { 'no-width': item.size.width === 'auto' || item.size.width === null, 'is-element-active': isElementActive(item.data.id), 'is-element-hovered': item.data.id === hoveredElementId, 'scene__element--texts': isTextElement(item), 'scene__element--images': isImageElement(item) }, elementClass(item)]"
    v-for="(item, index) in elements"
    :key="item.data.id"
    :x="itemObject(item).x"
    :y="itemObject(item).y"
    :w="itemObject(item).width"
    :h="itemObject(item).height"
    :draggable="false"
    :rotatable="false"
    :resizable="false"
    :selectable="false"
    :isAutoHeight="isText || item.size.height === 'auto'"
  )
    .scene__during(
      :class="[{ 'is-locked': item.lock, 'is-hidden': !item.show }]"
      :style="blurStyle"
    )
      ElementLoader(
        v-if="elementLoadingIds.includes(item.data.id)"
        :is-no-width-element="isCanvasElementNoWidth(item)"
        :element-type="item.type"
        :style="elementLoaderStyleObject(item)"
      )
      template(v-if="isCanvasElementNoWidth(item)")
        //- need to replace those first batch of template that has fixed width but no position x
        CanvasElementNoWidth(
          :item="item"
          :index="index"
          :isCanvas="isCanvas"
          :isScenePreview="isScenePreview"
          :isBrandedDesign="isBrandedDesign"
          :scale="scale"
          @mounting="addElementLoadingId(item.data.id)"
        )
      template(v-else)
        CanvasElement(
          :item="item"
          :index="index"
          :isSelectable="isCanvas"
          :isAllScenes="isAllScenes"
          :isSmartCreate="isSmartCreate"
          :animationTimeline="animationTimeline"
          :currentSceneStartTime="currentSceneStartTime"
          :hasBeenSet="isSet"
          :sceneTransition="transition"
          :sceneDuration="duration"
          :timelineSettings="item.timelineSettings"
          :sceneId="id"
          :totalMounted="totalElementMounted"
          :totalElements="elements.length"
          :isCaptureFrame="isCaptureFrame"
          :projectStartTimeDuration="projectStartTimeDuration"
          :isRenderingAtScale="isRenderingAtScale"
          :isBrandedDesign="isBrandedDesign"
          :videoShowSyncTimeout="videoShowSyncTimeout(item.data.id)"
          @element-is-set="setElement"
          @mounting="addElementLoadingId(item.data.id)"
          @mounted="addElementMounted"
          @destroyed="deleteElementMounted"
          @missing-media="missingMediaEvent(item.data.id)"
          @element-loaded="updateTotalElementsLoaded(item.data.id)"
        )
  CanvasWatermark(
    v-if="hasCustomWatermark"
  )
</template>

<script>
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';

import Vue from 'vue';
import { debounce } from 'debounce';
import { groups } from '@/assets/scripts/enums';
import helperMixin from '@/components/mixins/helper-mixins';
import drr from '@/plugins/@minogin/vue-drag-resize-rotate'; // eslint-disable-line

import CanvasElement from './CanvasElement.vue';
import CanvasElementNoWidth from './CanvasElementNoWidth.vue';
import CanvasWatermark from './CanvasWatermark.vue';
import ElementLoader from './ElementLoader.vue';

export default {
  name: 'CanvasScene',
  mixins: [helperMixin],
  data() {
    return {
      isSet: false,
      isAlertLoadingShow: false,
      /**
       * totalElementMounted is used to check the auto width element
       * and also to compare to element with animation
       */
      totalElementMounted: 0,
      backgroundColor: '',
      totalElementSet: 0,
      elementsAnimation: {},
      resetElementSet: null,
      /**
       * totalElementsLoaded is used to trigger scene loaded
       */
      totalElementsLoaded: 0,
      elementLoadingIds: [],
      previousElementIds: [],
    };
  },
  props: {
    background: { type: [String, Object, Array], default: '#fff' },
    scale: { type: Number, default: 1 },
    elements: { type: Array, default: () => [] },
    hoveredElementId: { type: String, default: '' },
    isCanvas: { type: Boolean, default: false },
    isAllScenes: { type: Boolean, default: false },
    isMagicScenes: { type: Boolean, default: false },
    isScenePreview: { type: Boolean, default: false },
    isSmartCreate: { type: Boolean, default: false },
    showMidScene: { type: Boolean, default: false },
    sceneClass: { type: String, default: '' },
    passedStyle: { type: Object, default: () => ({}) },
    animationTimeline: { type: String, default: 'mainTimeline' },
    currentSceneStartTime: { type: Number, default: 0 },
    duration: { type: Number, default: 0 },
    transition: { type: Object, default: () => ({}) },
    previousTransition: { type: Object, default: () => ({}) },
    sceneTimelineTimeout: { default: null },
    id: { type: Number },
    // populateElements: { type: Boolean, default: true },
    sceneIndex: { type: Number, default: 0 },
    sceneEntranceTiming: { type: Number, default: 0 },
    isCaptureFrame: { type: Boolean, default: false },
    projectStartTimeDuration: { type: Number, default: 0 },
    hasWatermark: { type: Boolean, default: true },
    isRenderingAtScale: { type: Boolean, default: false },
    sceneWidth: { type: Number, default: 0 },
    sceneHeight: { type: Number, default: 0 },
    isBrandedDesign: { type: Boolean, default: false },
  },
  components: {
    drr,
    CanvasElement,
    CanvasElementNoWidth,
    CanvasWatermark,
    ElementLoader,
  },
  computed: {
    ...mapState([
      'currentTime',
      'activeSceneIndex',
    ]),
    ...mapState('canvasElements', [
      'activeOverlay',
      'activeFGWhiteBg',
      'activeFGBlackBg',
      'sceneTimeline',
      'allScenesTimeline',
    ]),
    ...mapGetters(['isLiteMode']),
    ...mapGetters('canvasElements', ['getWatermark']),
    hasCustomWatermark() {
      return this.getWatermark.url && this.getWatermark.isEnabled;
    },
    checkIsPlaying() {
      // this is important to ensure animate during is working
      if (this.isCanvas && this.getIsPlaying) return true;
      if (this.isAllScenes && this.getIsAllScenesPlaying) return true;
      return false;
    },
    styleObject() {
      const styleObject = this.passedStyle;
      const bgColor = this.backgroundColor;
      const { scale } = this;
      if (bgColor) {
        if (this.activeOverlay || this.activeFGBlackBg || this.activeFGWhiteBg) {
          // this toggle is for designer tags (puppeteer)
          styleObject.background = 'transparent';
        } else if (bgColor) {
          if (bgColor.points) {
            // Gradient
            styleObject.background = `linear-gradient(${bgColor.degree}deg`;
            const { points } = bgColor;
            for (let i = 0; i < points.length; i += 1) {
              const point = points[i];
              styleObject.background += `, ${point.color} ${point.percentage}%`;
            }
            styleObject.background += ')';
          } else if (bgColor.color) {
            // added the checker if the background color is still old version
            styleObject.background = bgColor.color;
          } else {
            styleObject.background = bgColor;
          }
        }
      }

      styleObject.width = this.sceneWidth > 0 ? `${this.sceneWidth}px` : `${this.getCanvasSize.width}px`;
      styleObject.height = this.sceneHeight > 0 ? `${this.sceneHeight}px` : `${this.getCanvasSize.height}px`;

      if (scale > 0 && scale !== 1) {
        styleObject.transform = `scale(${scale})`;
      }

      if (this.isCanvas) {
        styleObject.width = `${this.getCanvasSize.width * this.getCanvasZoom}px`;
        styleObject.height = `${this.getCanvasSize.height * this.getCanvasZoom}px`;
      }

      return styleObject;
    },
    ...mapGetters([
      'isDownloadPreviewOpened',
      'getCanvasZoom',
      'getIsPlaying',
      'getIsAllScenesPlaying',
      'getCurrentAllScenesTime',
    ]),
    ...mapGetters('canvasElements', [
      'getActiveElements',
      'getCanvasSize',
      'getCurrentSceneElementsTime',
      'getCanvasElementsIds',
    ]),
    isAllScenesTimeline() {
      if (this.animationTimeline === 'allScenesTimeline') return true;
      return false;
    },
    isText() {
      if (this.getActiveElements.length === 0) return false;
      return this.getActiveElements.length === 1 && this.getActiveElements[0].type === 'texts';
    },
    populateElements() {
      return true;

      // TODO: fix for play all optimization
      // if (!this.isCaptureFrame) return true;
      // return this.sceneIndex === this.activeSceneIndex || (this.sceneIndex === this.activeSceneIndex + 1);
    },
    videoElementArray() {
      const videos = this.elements || [];
      return videos.filter(item => item.type === 'videos' && item.show).map(item => ({ id: item.data.id, url: item.data.url }));
    },
    blurStyle() {
      const style = {};

      // to ensure "blur", "drop-shadow", "reflection" effect showing without issue while playing elements that have animations like fadein + zoomin
      // fyi - this doesn't affect the appearance quality of element
      style.filter = 'blur(0.1px)';

      return style;
    },
  },
  methods: {
    ...mapMutations('animationSettings', [
      'seekTweenTimeline',
    ]),
    ...mapActions('animationSettings', [
      'setAllAnimation',
    ]),
    ...mapMutations('canvasElements', [
      'updateHasAutoWidthElement',
    ]),
    isCanvasElementNoWidth(item) {
      return item.size.width === 'auto'
      || item.size.height === 'auto'
      || item.size.width === null
      || item.size.height === null
      || typeof item.position.x === 'undefined';
    },
    isImageElement(item) {
      return item.isImage || item.type === 'images' || (item.data.url && item.data.url.split('.').pop() === 'png');
    },
    isTextElement(item) {
      return item.type === 'texts';
    },
    notAllowedOnLiteMode(item) {
      // only allow image and text to be clickable and interactable
      return this.isLiteMode && !(this.isImageElement(item) || this.isTextElement(item));
    },
    elementClass(item) {
      return {
        'is-not-allowed-on-lite-mode': this.notAllowedOnLiteMode(item),
      };
    },
    setTransition() {
      // only do this for allScenesTimeline and rendering
      if (!this.isAllScenesTimeline) return;
      // console.log('!!!! SET TRANSITION !!!!');
      const targetElement = this.$refs.canvasScene;
      const activeTimeline = this.animationTimeline;
      let startingTime = this.currentSceneStartTime;

      const activeTransition = this.transition;
      const { previousTransition } = this;

      const animateInValue = previousTransition.value ? previousTransition.value : '';
      const animateInDirection = previousTransition.direction ? previousTransition.direction : '';
      const animateOutValue = activeTransition.value ? activeTransition.value : '';
      const animateOutDirection = activeTransition.direction ? activeTransition.direction : '';

      // if there is no animate in or animate out, set the transition to be 0.03
      const animateInDuration = animateInValue ? 1 : 0.03;
      const animateOutDuration = animateOutValue ? 1 : 0.03;
      // add 1 second to the endingTime
      const endingTime = this.currentSceneStartTime + this.duration + animateOutDuration;

      if (startingTime < 0.1) {
        startingTime = 0.03;
      }

      this.$emit('scene-is-set', {
        id: this.id,
        animateInValue,
        animateInDuration,
        animateInDirection,
        animateOutValue,
        animateOutDuration,
        animateOutDirection,
        activeTimeline,
        endingTime,
        startingTime,
        targetElement,
      });
    },
    setElement(element) {
      if (!this.elementsAnimation[element.id]) {
        // only increase the totalElementSet if it's not there yet
        this.totalElementSet += 1;
      }
      this.elementsAnimation[element.id] = element;
    },
    setBlendClass(item) {
      if (item.blend && item.blend !== 'normal') return item.blend;
      return '';
    },
    updateScenePreviewTimeline() {
      if (!this.isScenePreview) return;
      // console.log('SETUP TIMELINE', this.sceneTimeline && this.sceneTimeline[this.id], this.id);
      if (!this.sceneTimeline[this.id]) return;
      this.sceneTimeline[this.id].clear();
      this.sceneTimeline[this.id].restart();
      this.sceneTimeline[this.id].addLabel('Start', 0);

      setTimeout(() => {
        this.sceneTimeline[this.id].seek(this.duration / 2);
      }, 500);
    },
    itemObject(item) {
      const itemObject = {
        x: item.position.x ? item.position.x : 0,
        y: item.position.y ? item.position.y : 0,
        width: item.size.width !== 'auto' ? item.size.width : 5,
        height: item.size.height !== 'auto' ? item.size.height : 5,
        angle: item.rotate || 0,
      };

      // for drr validator
      if (itemObject.width <= 0) itemObject.width = 1;
      if (itemObject.height <= 0) itemObject.height = 1;

      if (this.isCanvas) {
        itemObject.x *= this.getCanvasZoom;
        itemObject.y *= this.getCanvasZoom;
        itemObject.width *= this.getCanvasZoom;
        itemObject.height *= this.getCanvasZoom;
      }

      return itemObject;
    },
    addElementLoadingId(elementId) {
      if (this.elementLoadingIds.indexOf(elementId) === -1) this.elementLoadingIds.push(elementId);
    },
    removeElementLoadingId(elementId) {
      this.elementLoadingIds = this.elementLoadingIds.filter(value => value !== elementId);
    },
    addElementMounted() {
      this.totalElementMounted += 1;
    },
    deleteElementMounted(id) {
      this.totalElementMounted -= 1;
      Vue.delete(this.elementsAnimation, id);
    },
    updateTotalElementsLoaded(elementId) {
      this.removeElementLoadingId(elementId);
      this.totalElementsLoaded += 1;
      if (this.totalElementsLoaded === this.elements.length) this.$emit('scene-loaded');
    },
    elementLoaderStyleObject(item) {
      const styleObject = {};
      const isTextElement = item.type === groups.TEXTS || !item.type;

      /* eslint-disable */
      if (item.size.width === 'auto' || item.size.width === null) {
        const resizer = document.querySelector('.resizer-overlay');
        const isAllResizerStyleString = typeof resizer.style.height === 'string' && typeof resizer.style.top === 'string';
        const getStyleObjectNumbers = {
          height: isAllResizerStyleString ? parseInt(resizer.style.height.split('px')[0]) : resizer.style.height,
          top: isAllResizerStyleString ? parseInt(resizer.style.top.split('px')[0]) : resizer.style.top,
        };

        let topPosition = resizer.style.top;
        if (!isTextElement && isAllResizerStyleString) {
          let getVerticalCenterElement = (getStyleObjectNumbers.top + (getStyleObjectNumbers.height / 2));
          getVerticalCenterElement = getVerticalCenterElement - (getVerticalCenterElement / 10) // minus 10% so can become more center vertically
          topPosition = getVerticalCenterElement + 'px';
        }

        styleObject.width = resizer.style.width;
        styleObject.top = topPosition;
        styleObject.left = resizer.style.left;
      }

      return styleObject;
      /* eslint-enable */
    },
    isElementActive(elementId) {
      if (!this.getActiveElements.length) return false;

      const existingIndex = this.getActiveElements.findIndex(el => el.data && el.data.id === elementId);
      if (existingIndex >= 0) return true;
      return false;
    },
    resetElementsAnimationSet() {
      // if (this.isScenePreview) console.log('=====resetElementsAnimationSet====')
      this.totalElementSet = 0;
      this.elements.forEach((element) => {
        Vue.delete(this.elementsAnimation, element.data.id);
      });
    },
    missingMediaEvent(id) {
      Vue.delete(this.elementsAnimation, id);
    },
    elementIdsAreSameAsPrevious(newIds) {
      return JSON.stringify(this.previousElementIds) === JSON.stringify(newIds);
    },
    updatePreviousElementIds(value) {
      const copyOfValue = [...value]; // pass by value
      Vue.set(this, 'previousElementIds', copyOfValue);
    },
    // eslint-disable-next-line func-names
    debounceLoadingNotification: debounce(function (val, oldVal) {
      this.loadingNotification(val, oldVal);
    }, 500),
    loadingNotification(val) {
      if (val.length) {
        if (!this.isAlertLoadingShow) {
          this.alertLoading('Assets', '');
          this.isAlertLoadingShow = true;
        }
      } else {
        this.alertLoading('', '', true);
        this.isAlertLoadingShow = false;
      }
    },
    videoShowSyncTimeout(id) {
      if (this.isCaptureFrame || this.videoElementArray.length <= 8) {
        return 0;
      }

      const videoIndex = this.videoElementArray.findIndex(object => object.id === id);
      const timeout = videoIndex * 2000;
      return timeout <= 0 ? 0 : timeout;
    },
  },
  watch: {
    getCanvasElementsIds: {
      handler(value) {
        if (this.elementIdsAreSameAsPrevious(value)) return;
        // console.log('! elementIds has changed');

        this.updatePreviousElementIds(value);
        // make sure to reset the timeline for (duplicate element)
        this.$root.$emit('canvas-container-reset-timeline');
      },
      immediate: true,
    },
    isDownloadPreviewOpened() {
      if (this.isDownloadPreviewOpened && this.isAllScenesTimeline) {
        // if (this.isCanvas) console.log('isDownloadPreviewOpened', this.id);
        this.setTransition();
      }
    },
    totalElementMounted(val) {
      // console.log('totalElementMounted', val, this.elements.length);
      // if all already become CanvasElements,
      // it means there is no more auto width element
      if (val === this.elements.length) {
        this.updateHasAutoWidthElement(false);
      }
    },
    totalElementSet(val) {
      // if (this.isCanvas) console.log('totalElementSet:', val, 'mounted:', this.totalElementMounted, val === this.totalElementMounted);
      // cannot use element mounted cause sometimes it mounts too slowly
      if (val === this.elements.length) {
        const animation = {
          activeTimeline: this.animationTimeline,
          elements: this.elementsAnimation,
          sceneId: this.id,
        };

        if (this.isScenePreview) {
          this.updateScenePreviewTimeline();
        }

        if (this.isAllScenesTimeline) animation.tweenMaxTimeline = this.allScenesTimeline;

        this.setAllAnimation(animation);

        // console.log('totalElementSet reseted', this.totalElementSet)
        this.totalElementSet = 0;
        this.$emit('scene-elements-is-set');
      }
      if (val === 0) {
        // need to empty animations to ensure everytime the animations is resetted,
        // it will only apply to elements that still exists
        this.elementsAnimation = {};
      }
    },
    background: {
      immediate: true,
      deep: true,
      handler(val) {
        this.backgroundColor = val;
      },
    },
    duration: {
      handler(val) {
        if (!this.showMidScene) return;

        let timeline = this[this.animationTimeline];

        if (this.animationTimeline === 'sceneTimeline') {
          timeline = this[this.animationTimeline][this.id];
        }

        this.seekTweenTimeline({
          timeline,
          time: val / 2,
        });
      },
    },
    getCurrentSceneElementsTime: {
      handler() {
        // this watcher is for scene preview
        if (!this.isScenePreview) return;

        // console.log('++get current scen canvas scene triggerd', val);
        this.updateScenePreviewTimeline();

        const animation = {
          activeTimeline: this.animationTimeline,
          elements: this.elementsAnimation,
          sceneId: this.id,
        };
        this.setAllAnimation(animation);
      },
      deep: true,
    },
    elementLoadingIds: {
      handler(val, oldVal) {
        this.debounceLoadingNotification(val, oldVal);
      },
      immediate: true,
      deep: true,
    },
  },
  beforeMount() {
    // console.log('CANVAS SCENE BEFOER MOUNT', this.isCanvas, this.id);
    this.updateScenePreviewTimeline();
  },
  mounted() {
    // console.log('canvasScene.vue mounted~', this.isDownloadPreviewOpened, this.animationTimeline );
    // console.log('canvasScene.vue', this.sceneIndex, this.sceneEntranceTiming, this.getCurrentAllScenesTime, this.populateElements, this.activeSceneIndex);

    this.setTransition(); // have to trigger on mount, not sure why isDownloadPreviewOpened watcher is not triggering

    this.resetElementSet = debounce(() => {
      // console.log('==== on canvas-container-reset-timeline totalElementSet = 0');
      this.resetElementsAnimationSet();
    });

    // console.log('videoElementArray', this.videoElementArray);

    this.$root.$on('element-stop-resizing', this.resetElementSet);
    this.$root.$on('canvas-container-reset-timeline', this.resetElementSet);
  },
  beforeDestroy() {
    this.$root.$off('element-stop-resizing', this.resetElementSet);
    this.$root.$off('canvas-container-reset-timeline', this.resetElementSet);
    this.resetElementSet = null;
  },
};
</script>

<style lang="scss">
.scene.is-playing {
  cursor: auto;
  pointer-events: none;
}

.scene__element {
  position: absolute;
  pointer-events: none;

  &.no-width {
    position: static;
    width: auto !important;
    height: auto !important;
  }

  &.is-not-allowed-on-lite-mode {
    // only text and image should be clickable and interactable
    pointer-events: none !important;

    & * {
      pointer-events: none !important;
    }
  }

  &::before {
    display: none;
  }

  .scene__during > *:not(.is-locked):not(.is-hidden) {
    pointer-events: auto;
  }

  .canvas-element {
    top: 0 !important;
    left: 0 !important;
  }
}

.scene__during {
  width: 100%;
  height: 100%;
}
</style>
