<template lang="pug">
.element__loader(:class="{'loader--absolute-center': !useTextLoader && !isNoWidthElement}")
  .element__loader-bar(v-if="useTextLoader")
  BasePreloader.element__loader-spin(v-else :type="13")
</template>

<script>
import { groups } from '@/assets/scripts/enums';

export default {
  name: 'ElementLoader',
  props: {
    isNoWidthElement: { type: Boolean, default: false },
    elementType: { type: String, default: '' },
  },
  computed: {
    useTextLoader() {
      return this.elementType === groups.TEXTS || !this.elementType;
    },
  },
};
</script>

<style lang="scss">
.element__loader {
  width: 100%;
  margin: 0 auto;
  padding: 1px;
  position: absolute;
  top: -2px;

  &.loader--absolute-center {
    z-index: 5;
    top: 50% !important;
    right: 50%;
    transform: translate(50%, -50%);
  }

  .element__loader-bar {
    position: absolute;
    border-radius: 10px;
    top: 0;
    right: 100%;
    bottom: 0;
    left: 0;
    width: 0;
    animation: infiniteLoadingBar 2s linear infinite;
  }

  .element__loader-spin {
    .preloader__image {
      width: 45px !important;

      @include mid-desktop {
        width: 30px !important;
      }
    }
  }
}

.is-element-active {
  .element__loader {
    top: -3px;
  }
}

.is-element-hovered {
  .element__loader {
    top: -4px;
  }
}

@keyframes infiniteLoadingBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
    background: $red;
  }
  10% {
    left: 0%;
    right: 25%;
    width: 25%;
    background: $green;
  }
  50% {
    right: 60%;
    left: 40%;
    width: 25%;
    background: $blue;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
    background: $orange;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
    background: $red;
  }
}
</style>
