<template lang="pug">
.element-animated(
  :class="{ 'is-swap': isSwap, 'no-absolute': isImageType }"
  :style="styleObject"
  ref="element"
  )
  .js-animate.element
    .element-wrap
      template(v-if="isText")
        .text-content(
          contenteditable="false"
          :style="fontObject"
          v-html="content"
        )
      template(v-else-if="isVideo")
        //- For video, also check if it has audio
        //- need to be autoplay or it doesnt detect the audio
        video(
          ref="video"
          autoplay=true
          muted=true
        )
          source(:src="item.data.url")
      template(v-else-if="isImageType")
        img(
          :src="item.data.url"
          @load="onLoad"
        )
      template(v-else-if="isAnimated")
        .temp(v-html="animatedObject" :ref="item.data.id")
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import lottie from 'lottie-web';
import FontFaceObserver from 'fontfaceobserver';
import cloneDeep from 'lodash.clonedeep';
import layerMixin from '@/components/mixins/layer-mixins';
import textMixin from '@/components/mixins/text-mixins';
import { cleanUpContent, randomId } from '@/assets/scripts/utilities';

export default {
  name: 'CanvasElementNoWidth',
  mixins: [layerMixin, textMixin],
  props: {
    index: { type: Number, default: 0 },
    item: { type: Object, default: () => ({}) },
    isSwap: { type: Boolean, default: false },
    isCanvas: { type: Boolean, default: false },
    isScenePreview: { type: Boolean, default: false },
    scale: { type: Number, default: 0 },
  },
  data() {
    return {
      lottieName: '',
    };
  },
  computed: {
    ...mapState([
      'showStoryboardOverlay',
    ]),
    // ...mapGetters([
    //   'getCanvasZoom',
    // ]),
    ...mapGetters('canvasElements', [
      'getCanvasSize',
      'getCurrentSceneDuration',
    ]),
    styleObject() {
      const { x, y } = this.item.position;
      const { width, height } = this.item.size;
      const styleObject = {
        top: `${y}px`,
        left: `${x}px`,
        width,
        height,
      };

      if (this.item.position.x === null) {
        styleObject.x = '0px';
      }

      if (this.item.position.y === null) {
        styleObject.y = '0px';
      }

      return styleObject;
    },
    fontObject() {
      return this.item.data ? this.allTextElementStyles(this.item, false) : {};
    },
    // eslint-disable-next-line
    animatedObject() {
      this.$nextTick(() => {
        const copyData = cloneDeep(this.item.data);
        copyData.name = this.lottieName;
        // eslint-disable-next-line
        copyData.wrapper = this.$refs.element.getElementsByClassName('temp')[0];
        const animation = lottie.loadAnimation(copyData);

        animation.addEventListener('DOMLoaded', () => {
          animation.goToAndStop(1, true);
          this.updateSize();
        });

        // eslint-disable-next-line
        this.animation = animation;

        return animation;
      });
    },
    content() {
      let { content } = this.item.data;

      content = content.replace(/<br>$/, ''); // if it ends in <br>, remove it

      return content;
    },
    isImageType() {
      return this.isSvg || this.isImage || this.isMask || this.isShape;
    },
  },
  methods: {
    ...mapMutations('canvasElements', [
      'updateCanvasElementSizeFirstTime',
      'updateCanvasElementContent',
      'setCanvasElementHasAudio',
    ]),
    onLoad() {
      console.debug('onload');
      this.updateSize();
    },
    updateSize() {
      // console.log('update no width size', this.$refs.element)
      const activeElement = this.$refs.element;

      if (!activeElement) return;

      let width = activeElement.clientWidth;
      let height = activeElement.clientHeight;
      // console.log(width, height, activeElement, this.item.size.width, this.item.size.height);

      if (this.item.size.width && this.item.size.width !== 'auto') {
        // eslint-disable-next-line
        width = this.item.size.width;
      }

      if (this.item.size.height && this.item.size.height !== 'auto') {
        // eslint-disable-next-line
        height = this.item.size.height;
      }

      // console.log(width, height)

      // for any element, the max width height should be 3/4 of canvas
      let minWidth = 0.7 * this.getCanvasSize.width;
      let minHeight = 0.7 * this.getCanvasSize.height;

      // if background, use full width or height
      // or video elements
      // if (this.item.background || this.item.type === 'videos') {
      if (this.item.background) {
        minWidth = this.getCanvasSize.width;
        minHeight = this.getCanvasSize.height;
      }

      let { x, y } = this.item.position;
      // console.log(this.item.position)
      if (!this.isText) {
        x = this.getCanvasSize.width / 2;
        y = this.getCanvasSize.height / 2;
      }

      // set minimum width and height for shapes and masks
      if (this.isShape || this.isMask) {
        minWidth = 500;
        minHeight = 500;
      }

      if (
        (this.isShape
          || this.isMask)
        || (!this.isText)
      ) {
        // if it's shapes or mask
        // or is not text and the width is too big
        // calculate the new width and height

        let tempWidth = width;
        let tempHeight = height;
        let ratio = width / minWidth;

        tempWidth = minWidth;
        tempHeight = height / ratio;

        // console.table({
        //   width,
        //   height,
        //   ratio,
        //   tempWidth,
        //   tempHeight,
        //   isCanvas: this.isisCanvas,
        //   isSwap: this.isSwap,
        // });

        if (!this.item.background && tempHeight > minHeight) {
          // if not background, make sure the height is less than 0.75
          ratio = height / minHeight;
          tempHeight = minHeight;
          tempWidth = width / ratio;
        }

        width = tempWidth;
        height = tempHeight;
      } else if (
        this.item.type === 'videos'
        || this.item.type === 'graphics') {
        // todo: check for other elements
        // for old templates, some video element without width, will fill out the whole canvas based on canvas width
        // console.log('canvas item-->',width, height, minWidth, minHeight);

        // fill out the whole width of canvas
        let tempWidth = width;
        let tempHeight = height;

        const ratioWidth = width / this.getCanvasSize.width;
        const ratioHeight = height / this.getCanvasSize.height;

        if (tempWidth < this.getCanvasSize.width) {
          tempWidth = this.getCanvasSize.width;
          tempHeight = height / ratioWidth;
        }

        if (tempHeight > this.getCanvasSize.height) {
          tempWidth = width / ratioHeight;
          tempHeight = this.getCanvasSize.height;
        }

        width = tempWidth * 0.7;
        height = tempHeight * 0.7;

        // console.log('After canvas item-->',width, height, minWidth, minHeight, JSON.parse(JSON.stringify(this.item)));
      }

      if (this.item.background && height < minHeight) {
        // if background, make sure height will fill full height
        const ratio = height / minHeight;
        height = minHeight;
        width /= ratio;
      }

      // // since the size is from scene preview, need to calculate against the scale
      // width = width / this.scale;
      // height = height / this.scale;

      // todo: find offset for width so that it doesnt drop to 2 lines
      if (this.isText) {
        // when the font width is 260.21, the clientWidth returns as 260
        // add 1 px as offset for that
        // make it 5 for bold texts
        width += 5;
        height += 5;

        if (this.item.isNew) {
          x = this.getCanvasSize.width / 2;
          y = this.getCanvasSize.height / 2;
        } else if (typeof x === 'undefined') {
          x = this.item.position.left + width / 2;
          y = this.item.position.top + height / 2;

          // eslint-disable-next-line
          if (width > this.getCanvasSize.width && this.item.data.textAlign == 'center') {
            x = this.getCanvasSize.width / 2;
          }
        }
      }

      const update = {
        id: this.item.data.id,
        size: {
          width,
          height,
        },
        position: {
          x,
          y,
        },
      };

      console.log('HEREE');

      // removed condition to check whether is canvas
      // because for old project, other scenes may still have width and height auto
      // which won't work on preview all scenes
      // check if showStoryboardOverlay is true, then only run this on scenePreview
      if ((this.showStoryboardOverlay && this.isScenePreview)
        || (!this.showStoryboardOverlay
        && !this.isSwap
        && !(update.size.width === 0 && update.size.height === 0))) {
        console.log('HEREE2');
        // if this is text and there is <br> at the end of the line and space after <br>, replace them
        if (this.isText) {
          this.updateContent();
        }

        if ((!this.isVideo && height !== Infinity)
        || (this.isVideo && this.$refs.video.readyState >= 1)) {
          // don't override my files for video trimming hack
          if (this.isVideo) {
            update.max_duration = this.$refs.video.duration;
            // this.item.data.time_end = this.getCurrentSceneDuration;

            if (this.item.menu === 'my-files') {
              // get actual selected video duration
              // only update the time end if it exceed to the current scene duration
              const selectedVideoDuration = this.item.time_out - this.item.time_in;

              if (this.$refs.video.duration < this.getCurrentSceneDuration) {
                this.item.data.time_end = this.$refs.video.duration;
                this.item.time_out = this.$refs.video.duration;
              } else if (selectedVideoDuration > this.getCurrentSceneDuration) {
                this.item.data.time_end = this.getCurrentSceneDuration;
                this.item.time_out = this.getCurrentSceneDuration;
              }
            } else if (this.getCurrentSceneDuration > this.$refs.video.duration
                && this.item.animation_type !== 'loop'
                && this.item.animation_type !== 'freeze'
            ) {
              this.item.data.time_end = this.$refs.video.duration;
              this.item.time_out = this.$refs.video.duration;

              // console.log('set this default duration', JSON.stringify(this.item));
            } else {
              this.item.data.time_end = this.getCurrentSceneDuration;
              this.item.time_out = this.getCurrentSceneDuration;
            }
          }
          // console.table(update);
          // if (this.isText) console.log('update', update, this.item.data.content, update.size.width)
          // console.log('this next tick', this.isSwap, update.size, update.position, height, width, this.getCanvasZoom)
          this.updateCanvasElementSizeFirstTime(update);
        }
      }
    },
    updateContent() {
      const content = cleanUpContent(this.item.data.content);

      const contentObj = {
        id: this.item.data.id,
        content,
      };

      this.updateCanvasElementContent(contentObj);
    },
    checkAudio(event) {
      const video = event.target;
      console.log('check audio', video.webkitAudioDecodedByteCount);
    },
  },

  beforeMount() {
    this.$emit('mounting');
  },

  beforeDestroy() {
    // need to destroy to avoid memory leak
    if (this.animation) {
      this.animation.removeEventListener('DOMLoaded', () => {});
      this.animation = lottie.destroy(this.lottieName);
    }
  },

  mounted() {
    console.log('NO WIDTH MOUNTED', this.showStoryboardOverlay, !this.isScenePreview);
    // removed condition to check whether is canvas
    // because for old project, other scenes may still have width and height auto
    // which won't work on preview all scenes
    // if (!this.isCanvas && !this.isScenePreview) return;
    // check if showStoryboardOverlay is true, and this is not scenepreview, return
    if (this.showStoryboardOverlay && !this.isScenePreview) return;

    // this name is needed to destroy lottie
    this.lottieName = `lottie-${randomId()}`;

    this.$nextTick(() => {
      if (this.item.type !== 'videos'
      && !this.isImage
      && !this.isSvg
      && !this.isShape
      && !this.isMask
      && !this.isText
      && !this.item.animated) {
        console.log('next tick');
        this.updateSize();
      }
    });

    if (this.isVideo) {
      this.$refs.video.pause();
      this.$refs.video.onloadeddata = (event) => {
        const video = event.target;
        if (typeof this.item.data.has_audio === 'undefined') {
          // 2d fire effect has video.webkitAudioDecodedByteCount of 36
          // normal video has thousands, therefore need to add extra condition to only if the bytecount is bigger than 500
          const hasAudioTrack = video.mozHasAudio
                              || (Boolean(video.webkitAudioDecodedByteCount) && video.webkitAudioDecodedByteCount > 500)
                              || Boolean(video.audioTracks && video.audioTracks.length);

          // console.log('data loaded', video, this.elementId, hasAudioTrack, video.webkitAudioDecodedByteCount, video.audioTracks, video.mozHasAudio);
          const audioObj = {
            elementId: this.item.data.id,
            hasAudio: hasAudioTrack,
          };

          this.setCanvasElementHasAudio(audioObj);
        }

        this.updateSize();
      };
    }

    if (this.isText) {
      console.log(this.item.data.fontSize);
      const { fontFamily } = this.item.data;
      const style = {};
      if (this.item.data.fontWeight === 700) style.weight = 'bold';
      if (this.item.data.textItalic) style.style = 'italic';
      const font = new FontFaceObserver(fontFamily, style);
      const { element } = this.$refs;
      // console.log('THIS IS TEXTS')

      font.load(null, 30000).then(() => {
        // console.log(`${fontFamily} has loaded.`);
        if (element) {
          // console.log( 'resizerd', element.clientWidth, this.item.data.content)
          this.updateSize();
        }
      }).catch((error) => {
        console.error(`${fontFamily} failed to load.`, error);
        if (element) {
          this.updateSize(); // when fail just update the size anyway
        }
      });
    }
  },
};
</script>

<style scoped lang="scss">
.element-animated:not(.is-swap) {
  outline: 2px solid red;
  opacity: 0;

  // somehow mask doesn't load the right size if it's absolute
  // but it is fine for text
  &:not(.no-absolute) {
    position: absolute;
  }

  img { // to avoid extra space
    display: block;
  }

  .text-content {
    white-space: nowrap;
  }
}
</style>
