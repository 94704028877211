
import { mapState, mapGetters, mapMutations } from 'vuex';
import Api from '@/services/api/Api';

const renderMixin = {
  data() {
    return {
      // isDownloadingImage: false,
      sceneId: '',
      previewsCreated: 0,
      exportTries: 0,
    };
  },
  computed: {
    ...mapState([
      'isTemplate',
    ]),
    ...mapGetters([
      'getCurrentAllScenesTime',
      'getIsAllScenesPlaying',
      'getCurrentTime',
      'getDownloadPreviewType',
      'getFBBusinessAccountForPublish',
    ]),
    ...mapGetters('canvasElements', [
      'getProjectDetails',
      'getProjectId',
    ]),
    ...mapState('exportDetails', [
      'isDownloadingImage',
    ]),
    ...mapGetters('exportDetails', ['getExportDetailStates']),
    ...mapState('canvasElements', [
      'activeSceneId',
    ]),
    exportEndpointVersion() {
      return process.env.VUE_APP_EXPORT_VERSION === 'v1' ? 'v1' : 'v2';
    },
    currentAllScenesTime: {
      get() {
        return this.getCurrentAllScenesTime;
      },
      set(value) {
        // if this is project preview and video is playing, pause it
        if (!this.isCaptureFrame && this.getIsAllScenesPlaying) {
          this.isAllScenesPlaying(false);
          this.timelineBar.timeScale(this.timelineSpeed).pause();
          this.getAllScenesTimeline.timeScale(this.timelineSpeed).pause();
          this.$root.$emit('pauseAudio');
        }
        this.setCurrentAllScenesTime(value);
      },
    },
    isPublishingToFB() {
      return this.getDownloadPreviewType === 'publish';
    },
  },
  methods: {
    ...mapMutations([
      'setIsDownloadPreviewOpened',
      'setIsProjectPreview',
    ]),
    ...mapMutations('exportDetails', [
      'updateExportDetailState',
    ]),
    closeDownloadPreview() {
      this.setIsDownloadPreviewOpened(false);
      this.setIsProjectPreview(false);

      if (this.getIsAllScenesPlaying) {
        // playback is playing, toggle playAllscenes to pause;
        this.$root.$emit('playAllScenes');
      }
    },
    processRender(type, quality = 'high', videoExportScene = 'all') {
      if (type === 'jpg' || type === 'png') {
        const sceneId = this.activeSceneId;
        this.downloadImage(type, sceneId);
      } else {
        this.closeDownloadPreview();
        // reset data;

        this.updateExportDetailState({
          isExporting: true,
          isExportStarted: false,
          isTerminated: false,
          isCancelled: false,
          isCompleted: false,
          progress: 0,
          exportId: this.getProjectDetails.projectId,
          isTemplate: this.isTemplate,
          type,
          quality,
          scenePreviewLinks: [],
          videoExportScene,
          downloadLink: '',
          previewLink: '',
          cdnLink: '',
        });

        this.exportVideo();
      }
    },
    downloadImage(type, sceneId = '', createHeroThumbnails = true) {
      this.sceneId = sceneId;

      this.updateExportDetailState({
        isDownloadingImage: true,
      });

      this.previewsCreated = 0;

      let timing = this.currentAllScenesTime;

      if (this.isTemplate) {
        timing = this.getCurrentTime;
        this.updateExportDetailState({
          type,
          isExporting: true,
          scenePreviewLinks: [],
          scenePreviewTextCount: '',
          createHeroThumbnails,
        });
      }

      if (!this.getProjectDetails.projectId) return;

      const params = {
        id: this.getProjectDetails.projectId,
        is_template: this.isTemplate,
        type,
        timing,
      };

      if (this.isTemplate) {
        params.scene_id = this.sceneId;
        params.create_hero_thumbnails = createHeroThumbnails;
      }

      if (this.isPublishingToFB) {
        params.is_publishing_to_fb = this.isPublishingToFB;
        params.fb_account = this.getFBBusinessAccountForPublish;
      }

      Api.exportProject(params)
        .then((response) => {
          if (response.data) {
            // console.log(response.data);
            this.downloadImageStatus(type);
          }
        }).catch((error) => {
          console.log(error);
          setTimeout(() => {
            this.downloadImage(type);
          }, 1000);
        });
    },
    downloadImageStatus(type) {
      const params = {
        id: this.getProjectDetails.projectId,
        is_template: this.isTemplate,
        type,
        timing: this.currentAllScenesTime,
        progress: true,
        previews_created: this.previewsCreated,
        scene_id: this.sceneId,
      };

      let totalScenes = 1;

      totalScenes = this.sceneId.toString().split(',').length;

      const scenePreviewTextCount = `${this.previewsCreated} of ${totalScenes}`;

      this.updateExportDetailState({
        scenePreviewTextCount,
        totalScenes,
      });

      if (document.getElementById('download_frame') === null) {
        const iframe = document.createElement('iframe');
        iframe.id = 'download_frame';
        iframe.name = 'download_frame';
        iframe.style.height = '0';
        iframe.style.width = '0';
        iframe.style.position = 'absolute';
        iframe.style.opacity = '0';
        iframe.style.top = 0;
        iframe.style.left = 0;
        document.body.appendChild(iframe);
      }

      if (process.env.VUE_APP_PUSHER_ENABLE !== 'false') return; // websockets are enabled

      Api.exportProgress(params)
        .then((response) => {
          const { download_link, scene_preview_links, previews_created } = response.data;
          // console.log(download_link, download_link !== '');
          if (response.data) {
            if (this.isTemplate) {
              if (typeof scene_preview_links !== 'undefined' && scene_preview_links !== '') {
                // TODO: add preview images
                // show preview images
                this.updateExportDetailState({
                  isCompleted: true,
                  scenePreviewLinks: scene_preview_links,
                  scenePreviewTextCount: '',
                });
              } else {
                this.previewsCreated = previews_created;
                this.downloadImageStatus(type);
              }
            } else if (download_link !== '') {
              // target to iframe to avoid popup blocker
              const a = document.createElement('A');
              a.href = download_link;
              a.target = 'download_frame';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);

              this.updateExportDetailState({
                isDownloadingImage: false,
              });
            }
          }
        }).catch((error) => {
          console.log(error);
          setTimeout(() => {
            this.downloadImageStatus(type); // try to re-call the request
          }, 2000);
        });
    },
    cancelRendering() {
      // this.setIsRendering(false);

      this.isCancelling = true;

      const { isTemplate, type } = this.getExportDetailStates;
      let { exportId } = this.getExportDetailStates;

      if (!exportId) {
        exportId = this.getProjectId;
      }

      const params = {
        id: exportId,
        is_template: isTemplate,
        type,
      };

      Api.cancelExport(params, this.exportEndpointVersion)
        .then((response) => {
          if (response.data.success) {
            this.updateExportDetailState({
              isCancelled: true,
            });
            this.isCancelling = false;
          }
        }).catch((error) => {
          console.log(error);
          console.error(error);
          console.error(error.message);
          // setTimeout(() => {
          //   this.cancelRendering();
          // }, 1000);
        });
    },
    finishRender() {
      this.updateExportDetailState({
        isExporting: false,
        isCompleted: true,
      });
      // this.setIsRendering(false);
      // this.setIsRenderingCompleted(true);
    },
    exportVideo() {
      const {
        exportId, isTemplate, type, quality, videoExportScene,
      } = this.getExportDetailStates;

      console.log('exportVideo', this.getExportDetailStates);
      // this method is intended for video export only.
      if (type === 'jpg' && type === 'png') return;

      const params = {
        id: exportId,
        is_template: isTemplate,
        type: isTemplate ? 'mp4' : type, // if template force to use mp4
        quality: quality || 'high',
        is_full: videoExportScene === 'all',
      };

      if (this.isPublishingToFB) {
        params.is_publishing_to_fb = this.isPublishingToFB;
        params.fb_account = this.getFBBusinessAccountForPublish;
      }

      Api.exportProject(params, this.exportEndpointVersion)
        .then((response) => {
          this.exportTries = 0;
          if (response.data) {
            // const { current_export } = response.data;
            const queuePosition = this.exportEndpointVersion === 'v2' ? response?.data?.results?.queue_position : response?.data?.current_export?.queue_position;
            this.updateExportDetailState({
              isExportStarted: true,
              queuePosition: queuePosition || 1,
            });
            this.exportVideoStatus();
          }
        }).catch((error) => {
          console.log(error);
          const errorMessage = error?.response?.data?.message || 'Sorry, something went wrong.';
          setTimeout(() => {
            if (this.exportTries >= 3) {
              this.exportTries = 0;
              this.exportError(errorMessage);
              this.isCancelling = false;
              this.updateExportDetailState({
                isCancelled: true,
              });
              return;
            }
            this.exportTries += 1;
            this.exportVideo();
          }, 1000);
        });
    },
    exportVideoStatus() {
      const {
        exportId, isTemplate, quality, isTerminated, isCancelled, queuePosition,
      } = this.getExportDetailStates;

      if (isTerminated || isCancelled) return; // stop request

      let { type } = this.getExportDetailStates;

      const params = {
        id: exportId,
        is_template: isTemplate,
        type: isTemplate ? 'mp4' : type, // if template force to use mp4
        progress: true,
        quality: quality || 'high',
        queue_position: queuePosition,
      };

      if (process.env.VUE_APP_PUSHER_ENABLE !== 'false') return; // websockets are enabled
      Api.exportProgress(params)
        .then((response) => {
          if (response.data) {
            const {
              download_link, preview_link, cdn_link, results,
            } = response.data;

            const {
              progress, is_cancelled, is_failed, is_video_ready, queue_position,
            } = results;

            const updateExportDetailState = {
              progress,
              isCancelled: is_cancelled,
              isTerminated: is_failed,
              queuePosition: queue_position,
            };

            if (quality === 'high') type += '-hd';

            if (this.isTemplate && progress >= 100 && preview_link) {
              updateExportDetailState.isCompleted = true;
              updateExportDetailState.previewLink = preview_link;
            } else if ((is_video_ready || progress >= 100) && (download_link && preview_link && cdn_link)) {
              updateExportDetailState.isCompleted = true;
              updateExportDetailState.downloadLink = download_link;
              updateExportDetailState.previewLink = preview_link;
              updateExportDetailState.cdnLink = cdn_link;
            } else {
              // request call again
              setTimeout(() => {
                this.exportVideoStatus();
              }, 2000);
            }

            // console.table(updateExportDetailState);
            this.updateExportDetailState(updateExportDetailState);
          }
        }).catch((error) => {
          console.log(error);
          setTimeout(() => {
            this.exportVideoStatus(); // try to re-call the request
          }, 2000);
        });
    },
    exportError(errorMessage) {
      this.$notify({
        group: 'TopCenterNotif',
        title: 'Hey there!',
        type: 'error',
        text: `<i class="icon icon-close"></i>${errorMessage}`,
        duration: 5000,
        data: {
          errorMessage,
        },
      });
    },
  },
};

export default renderMixin;
